<template>
  <v-container class="d-flex justify-center">
    <v-card
      :width="isPhone ? '100%' : '70%'"
      class="transparent charcoalTileMenu mt-10 pa-2 paper--text d-flex flex-column"
    >
      <BaseScreenHeader title="Delete Account" screenInfo="ACT-012" />
      <v-card-title class="pl-0 paper--text">
        How to delete your
        <span class="mx-1 progressActive--text text-h5">BFitr </span> account.
      </v-card-title>

      <v-card-text class="paper--text px-2">
        Deleting your BFitr account is very simple. Follow the steps outlined
        below.
        <v-spacer />
        <ul class="my-5">
          <li>
            Go to the main menu.
          </li>

          <li>
            Click the "Account" button. This will open the account management
            screen (My Account).
          </li>
          <li>At the bottom of the screen, select "Delete Account".</li>
          <li>Confirm the account deletion.</li>
          <li>Click the "DELETE ACCOUNT" button to finalize the process.</li>
        </ul>
        <span class="silver--text">
          Please be aware that once deletion is initiated, all your personal
          data will be permanently removed. This action is irreversible and
          ensures complete erasure of your information.
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  name: 'DeleteAccountInformation.',
  components: {},
  data() {
    return {
      email: '',
      name: '',
      subject: '',
      message: '',
      success: false
    }
  },

  computed: {
    ...appConfig
  },
  mounted() {},
  methods: {}
}
</script>
